import { SearchProvider, SearchBox } from '@elastic/react-search-ui';
import SearchBoxView from './SearchBox';
import { config } from '@web/utils/searchConfig';
import {
  OutlinedInput,
  InputAdornment,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  ListItemText,
  Chip,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HistoryIcon from '@mui/icons-material/History';
import { Fragment, useEffect } from 'react';
import { H4 } from '@web/components/Typography';
import { currencyFormatter } from '@web/utils/currency';
import { useSearch } from '@web/context/search';
import OptimizedImage from '@web/components/OptimizedImage';
import { useDropdownContext } from '@web/context/dropdown';
import ClearIcon from '@mui/icons-material/Clear';

function InputView({
  getAutocomplete,
  getInputProps,
  inputValue,
  clearSelection,
}: any) {
  return (
    <Box sx={{ width: '100%' }}>
      <OutlinedInput
        fullWidth
        {...getInputProps({
          placeholder: 'Search for products...',
        })}
        sx={{ height: 42 }}
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlinedIcon fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          inputValue && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={clearSelection}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {getAutocomplete()}
    </Box>
  );
}

function getRaw(result, value) {
  if (!result[value] || !result[value].raw) return;
  return result[value].raw;
}

function getSnippet(result, value) {
  if (!result[value] || !result[value].snippet) return;
  return result[value].snippet;
}

function getSuggestionTitle(suggestionType, autocompleteSuggestions) {
  if (autocompleteSuggestions.sectionTitle) {
    return autocompleteSuggestions.sectionTitle;
  }

  if (
    autocompleteSuggestions[suggestionType] &&
    autocompleteSuggestions[suggestionType].sectionTitle
  ) {
    return autocompleteSuggestions[suggestionType].sectionTitle;
  }
}

function getSuggestionDisplayField(suggestionType, autocompleteSuggestions) {
  if (autocompleteSuggestions.queryType === 'results') {
    return autocompleteSuggestions.displayField;
  }

  if (
    autocompleteSuggestions[suggestionType] &&
    autocompleteSuggestions[suggestionType].queryType === 'results'
  ) {
    return autocompleteSuggestions[suggestionType].displayField;
  }
}

function AutocompleteView({
  autocompleteResults,
  autocompletedResults,
  autocompleteSuggestions,
  autocompletedSuggestions,
  className,
  getItemProps,
  getMenuProps,
  allAutocompletedItemsCount,
}) {
  const { frequentSearch } = useSearch();
  const { setDropdownOpen } = useDropdownContext();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  useEffect(() => {
    setDropdownOpen(true);
    return () => {
      setDropdownOpen(false);
    };
  }, []);
  let index = 0;

  return (
    <div
      {...getMenuProps({
        className: ['sui-search-box__autocomplete-container', className].join(
          ' '
        ),
      })}
    >
      <div>
        {allAutocompletedItemsCount === 0 && frequentSearch?.length && (
          <List className="sui-search-box__suggestion-list">
            {frequentSearch.slice(0, 15).map((searchQuery, i) => (
              <ListItem
                {...getItemProps({
                  key: searchQuery.name,
                  index: i,
                  item: searchQuery,
                })}
                key={searchQuery.name}
              >
                <ListItemIcon sx={{ justifyContent: 'center' }}>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText>{searchQuery.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
        {!!autocompleteSuggestions &&
          Object.entries(autocompletedSuggestions).map(
            ([suggestionType, suggestions]: any) => {
              return (
                <Fragment key={suggestionType}>
                  {suggestions.length > 0 && (
                    <List className="sui-search-box__suggestion-list">
                      {suggestions.map((suggestion) => {
                        index++;
                        if (suggestion.queryType === 'results') {
                          let displayField;
                          if (autocompleteSuggestions === true) {
                            displayField = Object.keys(suggestion.result)[0];
                          } else {
                            displayField = getSuggestionDisplayField(
                              suggestionType,
                              autocompleteSuggestions
                            );
                          }
                          const suggestionValue =
                            suggestion.result[displayField]?.raw;

                          return (
                            <ListItem
                              {...getItemProps({
                                key: suggestionValue,
                                index: index - 1,
                                item: {
                                  suggestion: suggestionValue,
                                  ...suggestion.result,
                                },
                              })}
                              key={suggestionValue}
                              data-transaction-name="query suggestion"
                            >
                              {suggestionValue}
                            </ListItem>
                          );
                        }

                        return (
                          <ListItem
                            {...getItemProps({
                              key:
                                suggestion.suggestion || suggestion.highlight,
                              index: index - 1,
                              item: suggestion,
                            })}
                            key={suggestion.suggestion || suggestion.highlight}
                            data-transaction-name="query suggestion"
                          >
                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                              <SearchOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {suggestion.highlight ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: suggestion.highlight,
                                  }}
                                />
                              ) : (
                                suggestion.suggestion
                              )}
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                </Fragment>
              );
            }
          )}
        {!!autocompleteResults &&
          !!autocompletedResults &&
          autocompletedResults.length > 0 && (
            <List>
              {autocompletedResults.map((result) => {
                index++;
                const titleField =
                  typeof autocompleteResults === 'boolean'
                    ? null
                    : autocompleteResults.titleField;

                const titleSnippet = getSnippet(result, titleField);
                const titleRaw = getRaw(result, titleField);
                const lowPriceRaw = getRaw(result, 'lowest_price');
                const highPriceRaw = getRaw(result, 'highest_price');
                const sellersRaw = getRaw(result, 'sellers');

                const priceRange =
                  !isMobile &&
                  lowPriceRaw &&
                  highPriceRaw &&
                  lowPriceRaw !== highPriceRaw
                    ? `${currencyFormatter.format(
                        lowPriceRaw
                      )}-${currencyFormatter.format(highPriceRaw)}`
                    : currencyFormatter.format(highPriceRaw);

                const imageRaw = getRaw(result, 'image').startsWith('http')
                  ? getRaw(result, 'image')
                  : '/assets/placeholder-image.webp';
                return (
                  <ListItem
                    {...getItemProps({
                      key: result.id.raw,
                      index: index - 1,
                      item: result,
                    })}
                    key={result.id.raw}
                  >
                    <ListItemIcon sx={{ paddingRight: 1 }}>
                      <OptimizedImage
                        src={imageRaw}
                        alt={titleRaw}
                        width={40}
                        height={40}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          aspectRatio: '1/1',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          fontSize="14px"
                          fontWeight="600"
                          color="text.secondary"
                          dangerouslySetInnerHTML={{ __html: titleSnippet }}
                        />
                      }
                      secondary={
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                            alignItems: 'center',
                          }}
                        >
                          {sellersRaw.length > 2 ? (
                            <>
                              <Chip label={sellersRaw[0]} size="small" />
                              <Chip label={sellersRaw[1]} size="small" />
                              <Box>{`+${sellersRaw.length - 2} more`}</Box>
                            </>
                          ) : (
                            sellersRaw.map((seller, index) => (
                              <Chip key={index} label={seller} size="small" />
                            ))
                          )}
                        </Box>
                      }
                    />
                    <H4 color="primary.main" fontWeight={600}>
                      {priceRange}
                    </H4>
                  </ListItem>
                );
              })}
            </List>
          )}
      </div>
    </div>
  );
}

export default function Search() {
  return (
    <SearchProvider
      config={{
        ...config,
        trackUrlState: false,
      }}
    >
      <SearchBox
        onSelectAutocomplete={(suggestion, config, defaultHandler) => {
          const searchTerm = suggestion.suggestion || suggestion.name;
          window.location.href = `/product/search?q=${encodeURIComponent(
            searchTerm
          )}`;
          defaultHandler(suggestion);
        }}
        onSubmit={(searchTerm) => {
          window.location.href = `/product/search?q=${encodeURIComponent(
            searchTerm
          )}`;
        }}
        autocompleteResults={{
          sectionTitle: 'Products',
          titleField: 'title',
          urlField: 'slug',
        }}
        autocompleteSuggestions={true}
        searchAsYouType={true}
        debounceLength={0}
        view={SearchBoxView}
        inputView={InputView}
        autocompleteView={AutocompleteView as any}
      />
    </SearchProvider>
  );
}
