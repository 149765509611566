'use client';

import { Box } from '@mui/material';
import { FlexBox } from '@web/components/flex-box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NextImage from 'next/image';
import { useDropdownContext } from '@web/context/dropdown';
import DumbLink from '@web/components/DumbLink';
import Search from '@web/components/layouts/Search';

const SearchBar = ({ isMobile }: any) => {
  const { dropdownOpen, setDropdownOpen } = useDropdownContext();

  const handleNavigateNextClick = () => {
    setDropdownOpen(false);
  };
  return (
    <FlexBox alignItems="center" sx={{ width: '100%' }}>
      {dropdownOpen && isMobile ? (
        <FlexBox ml={1} alignItems="center" sx={{ color: 'black' }}>
          <ArrowBackIosIcon onClick={handleNavigateNextClick} />
        </FlexBox>
      ) : (
        <DumbLink href="/" passHref>
          <FlexBox alignItems="center">
            <NextImage
              src="/assets/logo.webp"
              alt="Buy wisely"
              width={40}
              height={40}
            />
          </FlexBox>
        </DumbLink>
      )}
      {!isMobile && (
        <DumbLink href="/" passHref>
          <FlexBox alignItems="center">
            <NextImage
              priority
              src="/assets/text.png"
              alt="Buy wisely"
              width={150}
              height={27}
            />
          </FlexBox>
        </DumbLink>
      )}
      <Box width="100%" mx={isMobile ? 1 : 6}>
        <Search />
      </Box>
    </FlexBox>
  );
};

export default SearchBar;
